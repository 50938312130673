import React from "react"
import { graphql, Link } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import altStyles from "../templates/WhatWeDo.module.css"
import styles from "./Blog.module.css"
import HubspotForm from "components/HubspotForm"
import { Helmet } from "react-helmet"

// eslint-disable-next-line
export const BlogPage = ({ data, tags, headerImage, ...props }) => (
  <Layout {...props} seo={{ title: "OpsGuru Cloud Blog | The Latest Cloud News & Insights", description: "Looking for the latest and greatest cloud news and insights from world-class industry experts? The OpsGuru cloud blog is an invaluable cloud-focused resource." }}>
    {console.log(props)}
    <Helmet>
      {props.location.pathname.includes('/categories/') ? (<meta name='robots' content='noindex' />) : null}
    </Helmet>

    <Container className={altStyles.wrapper}>
      <div>
        <Title className={`${altStyles.title} ${altStyles.titlesmall}`}>Our Latest Cloud Insights</Title>
        <p className={altStyles.description}>News, updates, trends, and the latest information you need to know about OpsGuru and cloud migration.</p>
      </div>
      {headerImage ? (
        <div className={altStyles.image}>
          <img alt="Blog" title="Blog" src={headerImage.publicURL} loading="lazy" width="542" height="482"/>
        </div>
      ) : null}
    </Container>

    <div className={styles.tags}>
      <TitleBlock isLarger>Categories</TitleBlock>
      <Container>
        
        <div className={styles.otags}>
          <div className={cx({ [styles.active]: props.location.pathname.replace(/\//g, "") === "blog", })}>
            <Link
              to="/blog"
              className={cx({
                [styles.active]: props.location.pathname.replace(/\//g, "") === "blog",
              })}
            >
              All
            </Link>
          </div>
          {((tags && tags.group) || []).map(({ fieldValue }) => (
            <div key={fieldValue} className={cx({ [styles.active]: props.pageContext.tag === fieldValue, })}>
              <Link
                to={`/blog/categories/${fieldValue.replace(/\s/g, "-").toLowerCase()}`}
                className={cx({
                  [styles.active]: props.pageContext.tag === fieldValue,
                })}
              >
                {fieldValue}
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </div>

    <Container className={styles.posts}>
      {(data || [])
        .filter(Boolean)
        .filter(({ node }) =>
          props.pageContext.tag
            ? (node.frontmatter.tags || []).includes(props.pageContext.tag)
            : true
        )
        .map(({ node }, i) => (
          <article className={styles.post} key={i}>
            {node.frontmatter.image ? (
              <div className={styles.postimage}>
                <Img
                  loading="lazy"
                  objectFit="cover"
                  alt={node.frontmatter.title}
                  title={node.frontmatter.title}
                  fluid={node.frontmatter.image.childImageSharp.fluid}
                />
              </div>
            ) : null}
            <div className={styles.info}>
              <h2><Link to={`/post/${node.frontmatter.url}`}>{node.frontmatter.title}</Link></h2>
              <div className={styles.posttags}>
                {(node.frontmatter.tags || []).filter(Boolean).map((tag) => (
                  <Link key={tag} to={`/blog/categories/${tag.replace(/\s/g, "-").toLowerCase()}`}>
                    {tag}
                  </Link>
                ))}
              </div>
              {node.frontmatter.description ? (
                <div
                  className={styles.desc}
                  dangerouslySetInnerHTML={{ __html: marked(node.frontmatter.description.split(" ").splice(0,30).join(" ") + '...') }}
                />
              ) : null}
              <time dateTime={node.frontmatter.date}>
                {new Date(node.frontmatter.date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </time>
            </div>
          </article>
        ))}
    </Container>

    <Container className={styles.paginate}>
      {props.pageContext.previousPagePath ? (
        <Link to={props.pageContext.previousPagePath}>{`<`}</Link>
      ) : null}
      {Array.from({ length: props.pageContext.numberOfPages }, (_, i) => (
        <Link key={`pagination-number${i + 1}`} to={`/blog/${i === 0 ? "" : i + 1}`} className={cx({
          [styles.active]: props.pageContext.humanPageNumber === i + 1,
        })}>
          {i + 1}
        </Link>
      ))}
      {props.pageContext.nextPagePath ? (
        <Link to={props.pageContext.nextPagePath}>{`>`}</Link>
      ) : null}
    </Container>
    <div className={altStyles.grayBackground} id="contact-form">
      <Container>
      <div className={altStyles.titleContainer}>
          <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span>Learn more about how OpsGuru can help you with your cloud journey</TitleBlock>
        </div>
        <HubspotForm id="b586e1c3-2aa2-4cce-815e-a8fb546726bb" portalId="6237410" />
      </Container>
    </div>
  </Layout>
)

// eslint-disable-next-line
export const BlogPageWrapper = (props) => (
  <BlogPage
    {...props}
    tags={props.data.tags}
    data={props.data.blogPosts.edges}
    headerImage={props.data.headerImage}
  />
)

export default BlogPageWrapper

export const pageQuery = graphql`
  query BlogPage($skip: Int!, $limit: Int!) {
    headerImage: file(relativePath: { eq: "blog-intro.svg" }) {
      publicURL
    }
    tags: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "blog" } 
        },
        isFuture: { eq: false }
      }
      limit: 10000
    ) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: {
          templateKey: { eq: "blog" } 
        },
        isFuture: { eq: false }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            url
            title
            description
            tags
            date
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
